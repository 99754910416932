<template>
  <div class="c-activity" :class="`c-activity-unit--${unitSlug}`">
    <UnitHeader
      v-if="unitSlug"
      :unitSlug="unitSlug"
      type="video"
      :element="activity.name"
    />
    <VHeading
      header="h1"
      class="c-activity__title u-px o-wrapper o-wrapper--article"
    >
      <VideoIconBig class="c-activity__title-icon" />
      {{ activity.name }}
    </VHeading>

    <div class="c-activity__wrapper">
      <component
        v-for="section in activity.content"
        :key="section.id"
        :is="componentNameNormalize(section.__component)"
        :content="section"
        class="c-activity__item u-py"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { fetchSH } from '@/api'
import { componentNameNormalize } from '@/utils'
import VideoIconBig from '@/assets/svg/Icon_Video_Big'
import UnitHeader from '@/components/UnitHeader'
import VHeading from '@forlagshuset/v-heading'
import ComponentLearningElementTextBox from '@/components/LearningElements/TextBox'
import ComponentLearningElementTextElement from '@/components/LearningElements/TextElement'
import ComponentLearningElementArticleElement from '@/components/LearningElements/ArticleElement'
import ComponentLearningElementSectionTagElement from '@/components/LearningElements/SectionTagElement'
import ComponentLearningElementDbokLinkElement from '@/components/LearningElements/DbokLinkElement'
import ComponentLearningElementLtiElement from '@/components/ComponentLearningElements/LtiElement'
import ComponentLearningElementAudioElement from '@/components/ComponentLearningElements/AudioElement'
import ComponentLearningElementVideoElement from '@/components/ComponentLearningElements/VideoElement'

export default {
  name: 'Activity',

  components: {
    VideoIconBig,
    UnitHeader,
    VHeading,
    ComponentLearningElementTextBox,
    ComponentLearningElementTextElement,
    ComponentLearningElementArticleElement,
    ComponentLearningElementSectionTagElement,
    ComponentLearningElementDbokLinkElement,
    ComponentLearningElementLtiElement,
    ComponentLearningElementAudioElement,
    ComponentLearningElementVideoElement,
  },

  setup(props, ctx) {
    const activity = ref({})
    const activitySlug = computed(
      () => ctx.root.$options.router.history.current.params.activitySlug,
    )
    const unitSlug = computed(
      () => ctx.root.$options.router.history.current.params.unitSlug,
    )

    onMounted(async () => {
      activity.value = await fetchSH('activity-types/' + activitySlug.value)
    })

    return {
      activity,
      componentNameNormalize,
      unitSlug,
    }
  },
}
</script>

<style lang="scss">
.c-activity {
  &.c-activity-unit--echo-1-identity {
    .c-unit__header {
      background-color: $unit-identity-bg;
    }
  }
  &.c-activity-unit--echo-2-english-around-the-world {
    .c-unit__header {
      background-color: $unit-english-bg;
    }
  }
  &.c-activity-unit--echo-3-citizenship {
    .c-unit__header {
      background-color: $unit-citizenship-bg;
    }
  }
  &.c-activity-unit--echo-4-indigenous-peoples {
    .c-unit__header {
      background-color: $unit-indigenous-bg;
    }
  }
  &.c-activity-unit--echo-my-grammar {
    .c-unit__header {
      background-color: $unit-grammar-bg;
    }
  }

  .c-unit__header {
    @include bp-down($tablet) {
      display: none;
    }
  }
}

.c-activity__wrapper {
  padding-bottom: 5rem;
  @include bp-down($tablet) {
    padding: 0 1.25rem;
  }
}
.c-activity__title-icon {
  vertical-align: middle;
}

.c-header h1 {
  @include bp-down($tablet) {
    font-size: 1.5rem;
  }
}

.c-button {
  border: 0.0625rem solid $color-divider;
}

.c-video__wrapper {
  @include bp-down($tablet) {
    padding-bottom: 0;
  }
}

.c-activity__item {
  max-width: $article-width;
  width: 100%;
  margin: 0 auto;
  &.c-video {
    background-color: $color-white;
    width: 100%;
    max-width: 100%;
    @include bp-down($tablet) {
      padding-bottom: 1rem;
    }
  }
  .c-header h2,
  .c-text-element__title h3 {
    font-size: 1.5rem;
    font-weight: 300;
  }
}
</style>