<template>
  <div class="c-h5p__container">
    <VHeading header="h2" :text="content.contextTitle" />
    <VH5P :id="content.resourceLinkId" tabindex="0" width="100%" height="755">
      <template #instructions>
        <div v-md-html="content.instructions" />
      </template>
    </VH5P>
  </div>
</template>

<script>
import VH5P from '@forlagshuset/v-h5p'
import VHeading from '@forlagshuset/v-heading'

export default {
  name: 'H5PWrapper',

  components: {
    VH5P,
    VHeading,
  },

  props: {
    content: Object,
  },
}
</script>

<style lang="scss">
.c-h5p__container {
  @include bp($tablet) {
    max-width: 100%;
  }
  iframe {
    background: $color-white;
    padding: 0;
    @include bp($tablet) {
      max-width: 100%;
    }
  }

  .c-h5p__wrapper {
    margin: 0 auto 0 0;
    @include bp($tablet) {
      max-width: 100%;
    }
  }
}
</style>